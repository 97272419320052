import type { Breakpoints, Colors, Fonts, ElementsHeight } from 'types/theme'

export const breakpoints: Breakpoints = {
  base: 0,
  xs: 440,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  '3xl': 1600,
  '4xl': 1920,
  '5xl': 2400,
}

export const colors: Colors = {
  primary: '#72715c',
  secondary: '#72715c',
  dark: '#26291e',
  light: '#ede9e0',
  white: '#FFFFFF',
  black: '#000000',
  success: '#66B70D',
  warning: '#CA0F0F',
}

export const fonts: Fonts = {
  size: '10px',
}

export const elementsHeight: ElementsHeight = {
  navbar: {
    mobile: '80px',
    desktop: '100px',
  },
}

const theme = {
  breakpoints,
  colors,
  fonts,
  elementsHeight,
}

export default theme
