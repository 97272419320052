import React, { createContext, useState, useEffect, useMemo } from 'react'

type NavigationProviderProps = {
  children: React.ReactNode
}

type NavigationContextType = {
  isSidenavVisible: boolean
  closeSidenav: () => void
  openSidenav: () => void
  initialSearchParam: string
}

const defaultContextValues = {
  isSidenavVisible: false,
  closeSidenav: () => null,
  openSidenav: () => null,
  initialSearchParam: '',
}
const NavigationContext =
  createContext<NavigationContextType>(defaultContextValues)

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
  children,
}) => {
  const [isSidenavVisible, setIsSidenavVisible] = useState(false)
  const [initialSearchParam, setInitialSearchParam] = useState('')

  const closeSidenav = () => {
    if (isSidenavVisible) {
      setIsSidenavVisible(false)
      document.body.style.overflow = 'auto'
    }
  }

  const openSidenav = () => {
    if (!isSidenavVisible) {
      setIsSidenavVisible(true)
      document.body.style.overflow = 'hidden'
    }
  }

  useEffect(() => {
    setInitialSearchParam(window.location.search)
  }, [])

  const value = useMemo(
    () => ({
      isSidenavVisible,
      closeSidenav,
      openSidenav,
      initialSearchParam,
    }),
    [isSidenavVisible, initialSearchParam, closeSidenav, openSidenav]
  )

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationContext
